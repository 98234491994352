@import '../../App.css';

.card-link {
	text-decoration: none;
}

.card-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 130px;
	width: 130px;
	min-height: calc((18vh + 10vw) / 2);
	min-width: calc((18vh + 10vw) / 2);
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	box-shadow: 8px 8px 20px 3px var(--shadow);
	backdrop-filter: brightness(70%) blur(8px);
	cursor: pointer;
	transition: all .3s;
}

.card-wrapper:hover {
	transform: scale(1.02);
	transition: all .5s;
}

.card-image {
	position: absolute;
	width: fit-content;
	height: fit-content;
	max-height: 60%;
	max-width: 60%;
	transition: all .3s;
}

.card-wrapper:hover .card-image {
	filter: blur(2px);
	opacity: 0.4;
	z-index: 1;
	transition: all .3s;
}

.card-title {
	opacity: 0;
	font-weight: 600;
	font-size: calc((3vh + 1.5vw) / 2);
	color: var(--text-secondary);
	text-shadow: 0 0 0px var(--text-shadow);
	transition: all .3s;
}

.card-wrapper:hover .card-title {
	opacity: 1;
	z-index: 6;
	text-shadow: 0 0 1px var(--text-shadow);
	transition: all .5s;
}

.card-description {
	opacity: 0;
	color: var(--text-secondary);
	text-align: center;
	text-overflow: ellipsis;
	padding: 2px;
	transition: all .3s;
}

.card-wrapper:hover .card-description {
	opacity: 1;
	z-index: 6;
	transition: all .5s;
}
