@import '../../App.css';

.home-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
	gap: 20px;
}

.home-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}
