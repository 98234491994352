@import '../../App.css';

.carousel-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 30vh;
	width: 100%;
	padding-bottom: 10px;
	border-radius: 15px;
}

.carousel-container {
	display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc((25vh + 15vw) / 2), 1fr));
	justify-items: center;
	align-items: center;
  gap: 30px;
	min-height: 30vh;
	width: 90%;
	border-radius: 10px;
}

.carousel-title-container {
	display: flex;
	width: 100%;
}

.carousel-title {
	backdrop-filter: brightness(80%) blur(5px);
	padding: 7px 7px;
	border-radius: 15px;
	color: var(--text-primary);
	position: relative;
	margin-left: 5%;
	text-shadow: 0 0 2px var(--text-shadow);
	transition: all 0.5s;
}
