@import '../../App.css';

.language-selector-wrapper {
	display: flex;
	flex-direction: row;
	position: absolute;
	left: 5%;
	gap: 10px;
}

.language-selector-image {
	cursor: pointer;
	height: 30px;
}
